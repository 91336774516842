.merit-about {
    padding: 50px;
    /* background-image: url("../Images/tokyo-japan-cityscape.jpg"); */
    /* background-image: url("../Images/abimg2.png"); */
    background-image: url("../Images/g2.png");
    width: 100%;
    background-size: cover;
    background-position: bottom;
    position: relative;
  }

  .merit-about::before{
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    /* background-color:rgba(0, 0, 0, 0.5); */
  }
  
  .about-section {
    margin-bottom: 40px;
    text-align: center;
    position: relative;
    background-color: rgba(255, 255, 255, 0.5);
    
    /* background-color: red; */
  }
  
  .about-section h2 {
    font-size: 2em;
    margin-bottom: 10px;
    color: rgba(0, 0, 255, 0.5);
  }
  
  .about-section p {
    font-size: 1.2em;
    margin-bottom: 20px;
    /* color: #555; */
  }
  
  .about-section img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .who-we-are {
    padding: 50px;
    border-radius: 8px;
   
  }

  .who-we-are-child{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:50px;
  }
  
  .what-we-do {
    padding: 50px;
    border-radius: 8px;
   
  }

  .what-we-do-child{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:50px;
    flex-direction: row-reverse;
  }

 

  
  
  .our-vision {
    /* background-color: #e8f4fc; */
    padding: 20px;
    border-radius: 8px;
  }
  
  .our-mission {
    /* background-color: #fef7e8; */
    padding: 20px;
    border-radius: 8px;
  }
  
  .our-team {
    /* background-color: #f5f5f5; */
    padding: 100px;
    border-radius: 8px;
  }
  
  .team-grid {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .team-member {
    width:20%;
    text-align: center;
    /* background-color: red; */
  }
  
  .team-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .team-member h3 {
    margin-top: 10px;
    font-size: 1.2em;
  }
  
  .team-member p {
    font-size: 1em;
    color: #777;
  }

/* animation style */
  .about-section {
    opacity: 0;
    transform: translateY(200px);
    transition: opacity 1.5s ease-out, transform 1.5s ease-out;
  }
  
  .about-section.in-view {
    opacity: 1;
    transform: translateY(0);
  }

  @media screen and (max-width:884px) {
    .our-team{
      padding: 20px;
    }
    .who-we-are-child{
      flex-direction: column;
      gap: 10px;
    }
    .what-we-do-child{
      flex-direction: column;
      gap: 10px;
    }
  }
  
  @media (max-width: 768px) {
    .team-member {
      width:40%;
    }
  }
  
  @media (max-width: 480px) {
    .team-member {
      /* width: 100%; */
    }
  }
  

  @media screen and (max-width:428px) {
    .merit-about{
      padding: 0px;
      padding: 20px 10px;
    }
    .who-we-are{
      padding: 20px;
    } 
    .what-we-do{
      padding: 20px;
    } 
    .team-image{
      width: 60%;
    }
  }