.ManualCreditWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
}

.ManualCredit {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ManualCredit h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: rgba(0, 0, 255, 0.5);
}

.ManualCredit form {
    display: flex;
    flex-direction: column;
}

.ManualCredit input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    outline: none;
}

.ManualCreditButtonWrap{
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.ManualCredit button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
}

.ManualCredit button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}

@media (max-width: 600px) {
    .ManualCredit {
        padding: 15px;
    }

    .ManualCredit h2 {
        font-size: 1.25rem;
    }

    .ManualCredit input, .ManualCredit button {
        font-size: 0.875rem;
    }
}


@media screen and (max-width:428px) {
    .ManualCreditWrap{
        /* justify-content: flex-start; */
        align-items: flex-start;
    }   

    .ManualCredit{
        margin-top: 200px;
    }
}