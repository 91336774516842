/* src/CSS/Login.css */
.LoginWrap{
    padding: 100px 20px;
    background-image: url("../Images/bank.jpeg");
}


.login-container {
    max-width: 500px;
    margin: auto;
    padding: 20px;
    background:rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: rgba(0, 0, 255, 0.5);
}

.login-container form {
    display: flex;
    flex-direction: column;
}

.login-container input {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
}

.login-container button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.login-container button:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .login-container {
        padding: 15px;
    }

    .login-container input, .login-container button {
        font-size: 14px;
    }
}
