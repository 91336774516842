.meritan-footer {
    background-color: #333;
    background-color: rgba(0, 0, 255, 0.5);
    color: white;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: left;

  }


  
  .footer-section {
    flex: 1;
    min-width: 250px;
    margin: 10px;
  }

  .footer-section img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .footer-logo {
    font-size: 24px;
    font-weight: bold;
  }
  
  .footer-section h3 {
    margin-bottom: 10px;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 5px;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-around;
    font-size: 2rem;
  }
  
  .footer-bottom {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  
  .footer-bottom p {
    margin: 5px 0;
  }
  
  @media (max-width: 768px) {
    .footer-section {
      min-width: 100%;
      margin: 10px 0;
    }
  }
  