.meritan-contact-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 10%;
    flex-wrap: wrap;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../Images/ocean.png");
  }
  
  .meritan-map-container {
    flex: 1;
    /* min-width: 300px; */
    height: 500px;
    cursor: pointer;
  }
  
  .meritan-form-container {
    flex: 1;
    /* min-width: 300px; */
    padding: 20px;
  }
  
  .meritan-form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: rgba(0, 0, 255, 0.5);
  }
  
  .meritan-form-group {
    margin-bottom: 15px;
  }
  
  .meritan-form-group input,
  .meritan-form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .meritan-form-group textarea {
    resize: vertical;
    height: 100px;
  }
  
  .meritan-form-container button {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .meritan-form-container button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .meritan-contact-container {
      flex-direction: column;
    }
  
    .meritan-map-container,
    .meritan-form-container {
      width: 100%;
      margin: 10px 0;
    }
  }
  