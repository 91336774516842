.HeaderWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    flex-direction: column;
    text-align: center;
    padding-bottom: 7px;
    position: fixed;
    width: 100%;
    z-index: 999;
    color: white;
}   

.HeaderUp{
    width: 100%;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid whitesmoke;
    padding: 10px;
    font-size: 0.8rem;
    
}

.HeaderDown{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    

}

.HeaderWrap2{
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 0px 10px; */
    flex-direction: column;
    text-align: center;
    padding-bottom: 7px;
    position: fixed;
    width: 100%;
    z-index: 999;
    color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 255, 0.5);
}

.HeaderUp2{
    display: none;
}


.HeaderDown2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    color:black;
    padding: 10px;
}

.HeaderDown2 h2{
    color: rgba(0, 0, 255, 0.5);
}



.HeaderDownLeft{
    width: 25%;
    /* background-color: red; */
    display: flex;
    justify-content:center;
    align-items: center;
    gap: 5px;
}

.HeaderDownLeft img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.HeaderDownMid{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: blue; */
    width: 40%;
}

.menu1A{
    cursor: pointer;
}

.menu1B{
    cursor: pointer;
    color: rgba(0, 0, 255, 0.7);
    font-weight: bold;
}

.HeaderDownRight{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    gap: 20px;
    /* background-color: red; */
}

.HeaderDownRight select{
    width: 100px;
    /* padding: 3px; */
    cursor: pointer;
    outline: none;
}

.HeaderDownRight button{
    padding: 15px 20px;
    border: none;
    border-radius: 5px;
    color:white;
    /* background-color: rgba(0, 128, 0, 0.7); */
    background-color: rgba(0, 0, 255, 0.5);
    cursor: pointer;
    margin-top: 5px;
    background: linear-gradient(270deg, #00f, #0f0, #00f);
    background-size: 200%;
 

    animation: button-slide 3s ease infinite;
}

.MobileHomeButton{
    display: none;
    justify-content: center;
    align-items: center;
    gap:5px;
    color: rgba(0, 0, 255, 0.5);
    /* background-color: blue; */
}



.ListIcon{
    display: none;
    cursor: pointer;
}

.MobileMenu{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding: 10px 5px;
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    color: blue;
    /* text-decoration: underline; */
}
.MobileMenuLine{
    width: 95%;
    background-color: rgba(0, 0, 255, 0.4);
    height: 0.5px;
}




@media screen and (max-width:884px) {
    .HeaderDownMid{
        display: none;
    }
    .HeaderDownRight{
        width: 50%;
        justify-content: space-around;
    }

    .HeaderDownRight button{
        display: none;
    }
    .MobileHomeButton{
        display: flex;
    }
    .ListIcon{
        display: flex;
    }
    .HeaderDownLeft{
        width: 40%;
    }
    #AccountLogin{
        display: none;
    }
   
}

@media screen and (max-width:768px) {
    .HeaderUp{
        display: none;
    }
    .HeaderDown{
        padding: 15px 0px;
        /* background-color: blue; */
    }
    .HeaderDownLeft{
        justify-content: flex-start;
        width: 20%;
    }
    .HeaderDownLeft h2{
        display: none;
    }
    .HeaderDownRight{
        width: 80%;
    }
}