.verification-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  margin: auto;
  padding: 100px 20px;
  text-align: center;
}

.verification-page-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: rgba(0, 0, 255, 0.5);
}

.verification-page-description {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  text-align: center;
}

.verification-page-problem {
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.verification-page-problem-text {
  font-size: 1rem;
}

.verification-page-problem-question {
  /* font-size: 1.5rem; */
  font-weight: 500;
  font-style: italic;
  margin-top: 0.5rem;
}

.verification-page-input {
  padding: 0.75rem;
  font-size: 1rem;
  margin-top: 0.5rem;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  outline: none;
}

.verification-page-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
}


