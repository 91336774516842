.GalleryAWrap{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 50px;
    gap: 20px;
    background-color: rgba(0, 0, 255, 0.1);
}

.GalleryA1{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.GalleryA1 img{
    width: 30%;
    border-radius: 50%;
}

.GalleryA1 p{
    text-align: center;
    font-style: italic;
    font-weight: bold;
    font-size: 1.5rem;
    color: rgba(0, 0, 255, 0.555);
    animation: colorChange2 5s infinite;
}

@keyframes colorChange2 {
    0%{color: lightblue;}
    25%{color: rgba(255, 0, 0, 0.5);}
    50%{color: yellowgreen;}
    75%{color: rgba(0, 0, 255, 0.5);}
    100%{color: rgba(0, 128, 0, 0.5);}
  }

  .GalleryA1 video{
    width: 80%;
    height: 400px;
    border-radius: 10px;
    object-fit: cover;
  }
  


/* Container Layout */
.gallery-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 16px;
    width: 100%;
  }
  
  /* Media Item Styling */
  .media-item {
    width: 30%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    transform: scale(1);
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth scale and shadow transition */
  }
  
  /* Image and Video Styling */
  .media-item img,
  .media-item video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    
  }
  
  /* Specific Styling for the Video */
  .media-item video {
    object-position: 50% top; /* Aligns the video to the top */
  }
  
  /* Hover Effects */
  .media-item:hover {
    transform: scale(1.05); /* Slight zoom on hover */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
  }
  
  /* Fade-in Animation */
  .fade-in {
    animation: fadeIn 1s ease-out;
  }
  
  /* Keyframes for Fade-in */
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .videoMobile{
    display: none;
  }

  @media screen and (max-width:884px) {
    .media-item{
      height: 180px;
    }
    .gallery-container{
      padding: 0px;
    }
    .GalleryA1 p{
      font-size: 1.1rem;
    }
    .videoMobile{
      display: flex;
      /* width: 100%; */
      border-radius: 20px;
    }
    .GalleryA1 video{
      display: none;
    }
  }

  @media screen and (max-width:768px) {
    .media-item{
      width: 80%;
      height: 300px;
    }
    .GalleryA1{
      flex-direction: column;
      width: 100%;
    }

    .GalleryA1 img{
      width: 50%;
    }
    .GalleryA1 video{
      /* width: 100%; */
      height: 400px;
    }
  }
  
  /* Adjusting layout for smaller screens */
  @media (max-width: 768px) {
    .gallery-container {
      flex-direction: column;
    }

    
  }

  @media screen and (max-width:428px) {
    .media-item{
      width: 100%;
      height:170px;
    }
    .GalleryAWrap{
      padding: 10px;
    }
  }
  

  @media screen and (max-width:360px) {
    .videoMobile{
      display: flex;
      width: 100%;
      border-radius: 20px;
    }
  }