.meritan-gallery {
    padding: 20px;
    text-align: center;
    padding: 5%;
  }
  
  .gallery-title {
    font-size: 2em;
    margin-bottom: 10px;
    color: rgba(0, 0, 255, 0.5);
  }
  
  .gallery-description {
    margin-bottom: 20px;
    /* color: #555; */
  }
  
  .gallery-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .gallery-item {
    width: calc(33.333% - 20px);
    position: relative;
  }
  
  .gallery-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .gallery-caption {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    font-size: 0.9em;
  }
  
  @media (max-width: 768px) {
    .gallery-item {
      width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .gallery-item {
      width: 100%;
    }
  }
  