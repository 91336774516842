.features-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    flex-wrap: wrap;
    background-color: rgba(0, 0, 0, 0.7);
    background-color: rgba(0, 0, 255, 0.4);
    /* background-color: rgba(0, 128, 0, 0.5); */
    color: white;
    animation: colorChange 7s infinite;
  }

  @keyframes colorChange {
    0%{background-color: lightblue;}
    25%{background-color: rgba(255, 0, 0, 0.5);}
    50%{background-color: yellowgreen;}
    75%{background-color: rgba(0, 0, 255, 0.5);}
    100%{background-color: rgba(0, 128, 0, 0.5);}
  }
  
  .feature {
    text-align: center;
    max-width: 300px;
    margin: 10px;
  }
  
  .feature-icon {
    font-size: 40px;
    margin-bottom: 10px;
  }


  /* animate styles */
  .feature {
    opacity: 0;
    transform: translateY(200px);
    transition: opacity 1.5s ease-out, transform 1.5s ease-out;
  }
  
  .feature.in-view {
    opacity: 1;
    transform: translateY(0);
  }
  
  
  @media (max-width: 768px) {
    .features-container {
      flex-direction: column;
    }
  
    .feature {
      width: 100%;
      margin: 10px 0;
    }
  }
  