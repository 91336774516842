.DepositUiWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left:0;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
}


.DepositUi {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    

}

.DepositUi h2 {
    margin-bottom: 20px;
    color: rgba(0, 0, 255, 0.5);
}

.WalletAddress {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    color: rgba(0, 0, 255, 0.5);
}

.DepositUi button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 10px;
}

.DepositUi button:hover {
    background-color: #0056b3;
}

.Modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.Modal input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    outline: none;
}

.Modal button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
 
    margin-right: 10px;
}

.Modal button:hover {
    background-color: #0056b3;
}

@media (max-width: 600px) {
    .DepositUi {
        padding: 10px;
    }

    .Modal {
        padding: 15px;
    }
}
@media screen and (max-width:428px) {
    .DepositUi{
        width: 98%;
     
    }
   .WalletAddress{
    width: 90%;
    font-size: 0.9rem;

  
   }
}