

.AllUsersWrap{
    width: 100%;
    min-height: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    /* background-color: red; */
    position: absolute;
    top: 0px;
    left: 0;
    padding-top: 100px;
    padding-bottom: 100px;
}

.users-table-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    width: 80%;
    height: 100%;
    gap:0px;
    background-color:rgba(255, 255, 255, 0.5);
    /* background-color: red; */
  
}

.users-table-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: rgba(0, 0, 255, 0.7);
}


.users-grid {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    width: 100%;
    gap: 10px;
    justify-content: flex-start;
    padding-top: 20px;
    /* align-items: ; */
    overflow-y: scroll;
    height: 85%;
    /* background-color: red; */
}

.user-cardA {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.7);
    /* background-color: red; */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    padding: 15px;
    width: 100%;
    /* height: 185px; */
    
}

.user-info {
    /* margin-bottom: 10px; */
}

.user-info strong {
    /* display: block; */
    /* margin-bottom: 5px; */
}

.user-actions {
    margin-top: 10px;
}

.user-actions button {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.8rem;
}

.user-actions button:hover {
    background-color: #0056b3;
}

.AllUserCancelButton {
    background-color: white;
    border: 1px solid blue;
    color: blue;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
}

.AllUserCancelButton:hover {
    /* background-color: #c82333; */
}
@media screen and (max-width:428px) {
    .users-grid{
        width: 100%;
    }
    .users-table-container{
        width: 95%;
    }
}