/* General Styles */

.RegisterWrap{
    padding: 100px 20px;
    background-image: url("../Images/bank.jpeg");
}

.register-body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    /* background-color: #f4f4f4; */
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;

   
}

.register-container {
    background-color:rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    /* max-width: 600px; */
}

.register-title {
    text-align: center;
    margin-bottom: 20px;
    color: rgba(0, 0, 255, 0.5);
    font-size: 24px;
}

/* Form Styles */
.register-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.register-input,
.register-select,
.register-button {
    /* width: 30%; */
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    font-size: 16px;
}

.register-file-input {
    padding: 5px;
    border: none;
    background-color: #ffffff;
}

.register-input:focus,
.register-select:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.register-button {
    background-color: #007bff;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.register-button:hover {
    background-color: #0056b3;
}

.registerSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.registerSection input, .DateWrap{
    width: 30%;
}

.registerSection input::placeholder, .registerSection select{
    font-size: 0.8rem;
}

.DateWrap input{
    width: 100%;
}

@media screen and (max-width:884px) {
    .RegisterWrap{
        padding: 0px 0px;
    }
    .register-select{
        width: 30%;
    }
}


/* Responsive Design */
@media (max-width: 768px) {
    .register-container {
        padding: 15px;
        max-width: 100%;
        margin: 10px;
    }

    .register-input,
    .register-select,
    .register-button {
        font-size: 14px;
    }

    .register-title {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .register-title {
        font-size: 18px;
    }

    .register-input,
    .register-select,
    .register-button {
        font-size: 12px;
    }

    .register-button {
        font-size: 16px;
    }
}


@media screen and (max-width:428px) {
    .registerSection{
        flex-direction: column;
    }
    .registerSection input, .registerSection select{
        width: 100%;
    }
    .DateWrap{
        width: 100%;
        margin-top: 10px;
    }
    .RegisterWrap{
        padding: 50px 0px;
    }
    .register-container{
        width: 100%;
    }
    .register-body{
        width: 100%;
    }
}



/* Label Styles */
.register-label {
    font-size: 14px;
    color: #666666;
    margin-bottom: 5px;
}

/* File Input Styles */
.register-file-input {
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 8px;
    background-color: #ffffff;
    cursor: pointer;
}

/* Error and Success Messages */
.register-error-message {
    color: red;
    font-size: 14px;
    margin-top: -10px;
}

.register-success-message {
    color: green;
    font-size: 14px;
    margin-top: -10px;
}

/* SweetAlert overrides */
.swal2-popup {
    font-size: 16px;
}
