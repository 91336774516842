.ServicesWrap{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 50px;
    background-image: url("../Images/g2.png");
    position: relative;
    flex-wrap: wrap;
}

.ServicesWrap::before{
    content: "";
    top: 0;
    left:0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
}

.ServicesWrap h2{
    color: rgba(0, 0, 255, 0.5);
    text-align: center;
    position: relative;
    margin-bottom: 20px;
}

.ServicesContainer{
    position: relative;
    display: flex;
    width: 100%;
}

.ServicesContainer img{
    width: 50%;
    position: relative;
}

.ServicesRight{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap:20px;
    position: relative;
    
}

.Service{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:5px;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.3) ;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    height: 250px;
    animation: pulse 5s ease-in-out infinite;
}

@keyframes pulse {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
  }
  


.Service h3{
    color:rgba(0, 0, 255, 0.5)
}

.Service span{
    color:rgba(0, 0, 255, 0.5);
    font-size: 2rem;
}

.Service p{
    font-size: 0.9rem;
}


.Service2{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:5px;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.3) ;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(0, 0, 255, 0.5);
    height: 250px;
    
}

.Service2 h3{
    color: white;
}

.Service2 span{
    color:white;
    font-size: 2rem;
}

.Service2 p{
    font-size: 0.9rem;
    color: white;
}



  

@media screen and (max-width:884px) {
    .ServicesContainer{
        flex-direction: column;
        gap: 50px;
        
    }   
    .ServicesContainer img{
        width: 100%;
        height: 500px;
    }
    .ServicesRight{
        width: 100%;
    }
}

@media screen and (max-width:428px) {
    .ServicesContainer img{
        height: 300px;
    }
    .ServicesRight{
        flex-direction: column;
    }
    .Service, .Service2{
        width: 100%;
        height: auto;
    }
    .ServicesWrap{
        padding: 0px;
        padding: 50px 30px;
    }
}

@media screen and (max-width:360px) {
    .ServicesWrap{
        padding: 50px 20px;
    }
    .ServicesContainer img{
        height: 250px;
    }
}