
/* Existing Dashboard CSS */
.DashboardWrap {
    padding: 100px 20px;
    background-image: url("../Images/dashImg.png");
    background-size: cover;
    position: relative;
}

.DashboardWrap::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.438);
}

.dashboardContainer {
    width: 70%;
    height: 100%;
    padding: 20px;
    margin: auto;
    position: relative;
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard {
    padding: 20px;
    width: 50%;
    margin: auto;
    position: relative;
}

.dashboard h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: rgba(0, 0, 255, 0.6);
}

.dashboard img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.dashboard p {
    font-size: 0.95rem;
    margin-bottom: 10px;
}

.dashboard h2 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: rgba(0, 0, 255, 0.6);
}

.dashboard ul {
    list-style-type: none;
    padding: 0;
}

.dashboard li {
    font-size: 16px;
    margin-bottom: 5px;
}

.dashboard button {
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 255, 0.5);
    background-color: white;
    margin-top: 50px;
}

.BalanceWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    width: 40%;
}

.Eye {
    font-size: 1.3rem;
    cursor: pointer;
    color: rgba(0, 0, 255, 0.5);
}

.dashboardRight {
    width: 50%;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.TransactionHistory {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 5px;
    align-items: center;
    width: 100%;
    height: 80%;
    background-color: rgba(255, 255, 255, 0.5);
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.TransactionHistory p {
    /* color: gray; */
    font-size: 0.9rem;
}

.TransactionHistory h2 {
    color: rgba(0, 0, 255, 0.5);
}

.TransactionHistory button {
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    color: white;
    border: none;
    /* border: 1px solid rgba(0, 0, 255, 0.5); */
    background-color: rgba(0, 0, 255, 0.5);
    /* margin-top: 50px; */
}

.TransferButton {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    color: white;
    font-weight: bold;
    background-color: rgba(0, 0, 255, 0.5);
    margin-bottom: 10px;
}

.TransferModalWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left:0;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
}

.TransferModal {
    /* position: fixed;
    top: 50%;
    left: 50%; */
    /* transform: translate(-50%, -50%); */
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 50%;
}

.TransferModal input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 255, 0.5);
    /* margin-bottom: 10px; */
    outline: none;
}

.TransferModalButtonWrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
}

.TransferModal button {
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 255, 0.5);
    background-color: rgba(0, 0, 255, 0.7);
    color: white;
    font-weight: bold;
}

.TransferModal p {
    font-size: 1rem;
    color: rgba(0, 0, 255, 0.7);
    font-weight: bold;
}

.Transactions{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 10px;
    height: 100%;
    overflow-y: scroll;
}


.Transaction{
    display: flex;
    width: 100%;
    padding: 5px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    font-size: 0.9rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.33);
    /* color: gray; */
    border-radius: 5px;
}

.LogoutB{
    display: none;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    color: rgba(0, 0, 255, 0.5);
    font-weight: bold;
    border: 1px solid rgba(0, 0, 255, 0.534);
    background-color:white;
    margin-top: 50px;

}

.TransferDepositWrap{
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
}

.dashboardAdmin{
    display: flex;
    flex-direction: column;

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    padding: 5px;
    margin-bottom: 10px;
}

.dashboardAdmin h3{
    color: rgba(0, 0, 255, 0.5);
}

.dashboardAdmin p{
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width:884px) {
    .dashboardContainer{
        width: 90%;
    }
    .TransferModal{
        top:40%;
    }
    .TransferModalButtonWrap{
        width: 80%;
    }
}

@media screen and (max-width:768px) {
    .dashboardContainer{
        flex-direction: column;
        padding-bottom: 30px;
    }
    .dashboardRight{
        width: 100%;
    }
    .LogoutB{
        display: flex;
    }
    .LogoutA{
        display: none;
    }
    .dashboard{
        width: 100%;
    }
}

@media screen and (max-width:428px) {
    .dashboardContainer{
        width: 100%;
    }
    .DashboardWrap{
        padding: 100px 5px;
    }
    .dashboardContainer{
        padding: 10px;
    }
    .TransferModal{
        width: 100%;
    }
}