.AccountRegisterWrap{
    padding: 100px 0px;
}


.account-register-container {
    
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .account-register-container h1 {
    text-align: center;
    margin-bottom: 20px;
    color: rgba(0, 0, 255, 0.5);
  }
  
  .account-register-container form {
    display: flex;
    flex-direction: column;
  }
  
  .account-register-container input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    outline: none;
  }
  
  .account-register-container button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .account-register-container button:hover {
    background-color: #0056b3;
  }

  .AccountFormSection1{
    display: flex;
    gap:5px
  }

  @media screen and (max-width:884px) {
      .AccountRegisterWrap{
        /* padding: 50px 10px; */
      }
  }
  
  @media (max-width: 600px) {
    .account-register-container {
      padding: 10px;
    }
    
    .account-register-container input {
      padding: 8px;
    }
    
    .account-register-container button {
      padding: 8px;
    }
  }
  