.HeroWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: url("../Images/tokyo-japan-cityscape.jpg"); */
    background-size: cover;
    background-position: top;
    width: 100%;
    /* background-color: red; */
    height: 750px;
    background-color: rgba(0, 0, 255, 0.555);
    position: relative;
}

.HeroWrap::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.HeroMediaB{
  display: flex;
  position: absolute;
  top:0px;
  left:0px;
  width: 100%;
  /* height: 1000px; */
  /* object-fit: cover; */
  z-index: 0;
  object-position: top;
  /* height: 100%; */
  /* padding: 100px; */

}

.HeroMedia{
  display: none;
}





.Hero{
    display: flex;
    position: relative;
    justify-content:center;
    align-items: flex-start;
    width: 80%;
    flex-direction: column;
    color: white;
    gap: 10px;
}

.Hero h1{
    font-size: 3.5rem;
}

.Hero p{
    font-style: italic;
}

.Hero button{
    padding: 15px 20px;
    border: none;
    border-radius: 5px;
    color:white;
    /* background-color: rgba(0, 128, 0, 0.7); */
    background-color: rgba(0, 0, 255, 0.5);
    cursor: pointer;
    margin-top: 10px; 
}



/* animation styles */

@keyframes gradient-animate {
    100% {
      background-position: 0%;
    }
    0% {
      background-position: 100%;
    }
  }
  
  .Hero h1 {
    font-size: 3.5rem;
    background: linear-gradient(90deg, rgb(255, 0, 234), #0f0, white);
    background-size: 300%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradient-animate 3s linear infinite;
  }

  @keyframes text-reveal {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  
  .Hero p {
    font-style: italic;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    animation: text-reveal 5s ease-out infinite;
  }
  
  .Hero p span {
    display: inline-block;
    white-space: normal;
    overflow: hidden;
  }
  

  @keyframes button-slide {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 200% 0%;
    }
  }
  
  .Hero button {
    padding: 15px 20px;
    border: none;
    border-radius: 5px;
    color: white;
    background: linear-gradient(270deg, #00f, #0f0, #00f);
    background-size: 200%;
    cursor: pointer;
    margin-top: 10px;
    animation: button-slide 3s ease infinite;
  }
  

/* mobile responsieness */

@media screen and (max-width:884px) {
  .HeroMedia{
    display: flex;
    position: absolute;
    top:0px;
    left:0px;
    width: 100%;
    height: 800px;
    object-fit: cover;
    z-index: 0;
    object-position: top;
    /* height: 100%; */
  
  }
  .HeroWrap{
    height: 800px;
  }

  .HeroMediaB{
    display: none;
  }
}


@media screen and (max-width:428px) {
    .HeroMedia{
      height: 600px;
    }
    .HeroWrap{
      height: 600px;
    }
    .Hero h1{
        font-size: 2.5rem;
    }
}