.ProfileEditModalWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left:0;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
}

.profile-edit-modal-container {
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.profile-edit-modal-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: rgba(0, 0, 255, 0.5);
}

.profile-edit-modal-group {
    margin-bottom: 15px;
}

.profile-edit-modal-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: rgba(0, 0, 255, 0.5);
}

.profile-edit-modal-group input[type="text"],
.profile-edit-modal-group input[type="file"] {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    outline: none;
}

.profile-edit-modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.profile-edit-modal-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.profile-edit-modal-buttons button:hover {
    background-color: #0056b3;
}

.profile-edit-modal-buttons button:nth-child(1) {
    background-color: white;
    color: blue;
    border: 1px solid blue;
}


