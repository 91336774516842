.gmc{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    border-bottom: 5px solid rgba(0, 0, 255, 0.1);
}

.gmc img{
    width: 20%;
    animation: flip 5s infinite;
}

@keyframes flip {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}



@media screen  and (max-width:768px) {
    .gmc img{
        width: 50%;
    }
}